<template>
  <div>
    <vs-divider class="mb-5" />
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <span class="leading-none font-medium underline">
          <strong>Flight Search Criteria</strong></span
        >
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Preferred Cabin Class:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ flightSearchData.preferredCabinClass }}</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Direct Preferred:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ flightSearchData.directPreferred }}</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Carrier:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ flightSearchData.carrier }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full mt-2">
        <span><strong> Legs:</strong></span>
      </div>
    </div>   
      <template v-for="(leg, legIndex) in flightSearchData.legs">
         <div class="vx-row" :key="legIndex + '01'">
        <div class="vx-col" :key="legIndex + '0'">
          <span> {{ leg.originName }} - {{ leg.destinationName }}</span>
        </div>
        <div class="vx-col" :key="legIndex + '1'">
          <span> {{ getDateFormat(leg.departDate) }}</span>
        </div>
        <div class="vx-col" :key="legIndex + '2'">
          <span> {{ leg.timePreference }} Time</span>
        </div>
         </div>
      </template>

    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full mt-2">
        <span><strong> Passengers:</strong></span>
      </div>
    </div>
    
      <template v-for="(pass, passengerIndex) in flightSearchData.passengers">
        <div class="vx-row" :key="passengerIndex + '02'">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
        </div>
      </template>
    
  </div>
</template>

<script>
import enquiryViewerStore from "../enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";

export default {
  name: "FlightSearchCriteria",
  components: {
    flatPickr,
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    flightSearchData: {
      type: Object,
    },
    distUnit:{
      type: String
    }
  },
  methods: {
    getDateFormat(dateParam) {
      var date = new Date(dateParam);
      //required format 10-Sep-2021
      var day = "";
      if (date.getDate() < 10) {
        day = "0" + date.getDate();
      } else {
        day = date.getDate() + "";
      }
      var month = date.toLocaleString("default", { month: "short" });
      var year = date.getFullYear();
      var formattedDate = day + "-" + month + "-" + year;
      return formattedDate;
    },
  },
};
</script>

<style>
</style>
