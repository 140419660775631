<template>
  <div class="float-right" id="map" ref="map"></div>
</template>

<script>
export default {
  name: "GoogleStaticMap",
  components: {},
  props: {
    multiMarkMap: {
      type: Array,
    },
  },
  data: () => ({
    marker: null,
    map: null,
    circle: null,
  }),
  mounted() {
    this.map = new window.google.maps.Map(this.$refs["map"], {
      center: this.multiMarkMap[0].latlon,
      zoom: 12,
      mapTypeId: "roadmap",
      // mapTypeId: google.maps.MapTypeId.ROADMAP,
      tilt: 45,
      draggable: false,
      streetViewControl: false,
      mapTypeControl: false,
      //disableDefaultUI: true,// remove all options (zoom in/out,map type and all other)
    });

    for (let index = 0; index < this.multiMarkMap.length; index++) {
      this.marker = new window.google.maps.Marker({
        position: this.multiMarkMap[index].latlon,
        map: this.map,
        title: this.multiMarkMap[index].placeName,
      });

      this.circle = new window.google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: this.map,
        center: this.multiMarkMap[index].latlon,
        radius: this.multiMarkMap[index].radius,
      });
      // this.circle.bindTo('center', this.marker, 'position');
    }
  },
  methods: {
    getZoomLevel(radius) {},
  },
  beforeDestroy() {
    this.marker.setMap(null);
    window.google.maps.event.clearInstanceListeners(this.marker);
  },
  render() {
    return null;
  },
};
</script>

<style scoped>
#map {
  height: 300px;
  width: 350px;
  background: gray;
}
</style>
