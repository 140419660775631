<template>
  <div>
    <vs-divider class="mb-5" />
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <span class="leading-none font-medium underline">
          <strong>Transfer Search Criteria</strong></span
        >
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Point 1 Name:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ transferData.point1Name }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Point 1 Latitude / Longitude:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ transferData.point1Latitude }} / {{ transferData.point1Longitude }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Point 2 Name:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ transferData.point2Name }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Point 2 Latitude / Longitude:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ transferData.point2Latitude }} / {{ transferData.point2Longitude }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Dates:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ getDateFormat(transferData.outPickupDateTime,transferData.returnPickupDateTime)  }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full mt-2">
        <span><strong> Passengers:</strong></span>
      </div>
    </div>
    <!-- <div class="vx-row">
      <template v-for="(pass, passengerIndex) in transferData.passengers">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
      </template>
    </div> -->
    <template v-for="(pass, passengerIndex) in transferData.passengers">
        <div class="vx-row" :key="passengerIndex + '01'">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
        </div>
      </template>

      <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full"> 
     <GoogleStaticMap :mapLatLong="latLong" :circleRadius="radiusmeter" :location="place" :multiMarkMap="mapdata"></GoogleStaticMap>
      </div>
    </div>
    
  </div>
</template>

<script>
import enquiryViewerStore from "../enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";
import GoogleStaticMap from "./GoogleStaticMap.vue"

export default {
  name: "TransferSearchCriteria",
  components: {
    flatPickr,
    GoogleStaticMap
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    transferData: {
      type: Object,
    },
    distUnit:{
      type: String
    }
  },
  computed:{     
    mapdata(){
      return this.getMapData();
    }
  },
  methods: {
    getMapData(){
      let dataArray=[];
      let dataObject = {
        radius:0,
        latlon :{},
        placeName:""
      };
      dataObject.latlon = {lat: this.transferData.point1Latitude, lng: this.transferData.point1Longitude};
      dataObject.placeName = this.transferData.point1Name;
      dataArray.push(dataObject);
      dataObject.latlon = {lat: this.transferData.point2Latitude, lng: this.transferData.point2Longitude};
      dataObject.placeName = this.transferData.point2Name;
      dataArray.push(dataObject);
      return dataArray;

    },
   
    getDateFormat(checkin,checkout) {
      var checkinDate = new Date(checkin);
      var checkoutDate = new Date(checkout);
      //required format 10-Sep-2021

      //for start date time
      var dayin = "";
      if (checkinDate.getDate() < 10) {
        dayin = "0" + checkinDate.getDate();
      } else {
        dayin = checkinDate.getDate() + "";
      }
      var monthin = checkinDate.toLocaleString("default", { month: "short" });
      var yearin = checkinDate.getFullYear();
      var timein = checkinDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      var formattedDateIn = dayin + "-" + monthin + "-" + yearin + " " + timein;
      
      // for end date time
       var dayout = "";
      if (checkoutDate.getDate() < 10) {
        dayout = "0" + checkoutDate.getDate();
      } else {
        dayout = checkoutDate.getDate() + "";
      }
      var monthout = checkoutDate.toLocaleString("default", { month: "short" });
      var yearout = checkoutDate.getFullYear();
      var timeout = checkoutDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      var formattedDateOut = dayout + "-" + monthout + "-" + yearout + " " + timeout;
      //Night Difference
      var night = checkoutDate.getDate() - checkinDate.getDate();
      var formattedDate = formattedDateIn + " - " + formattedDateOut ;
      return formattedDate;
    },
  },
};
</script>

<style>
</style>
