<template>
  <div>
    <vs-divider class="mb-5" />
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <span class="leading-none font-medium underline">
          <strong>Airport Extras Search Criteria</strong></span
        >
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col ">
            <span><strong> Extra Type:</strong></span>
          </div>
          <div class="vx-col ">
            <span> {{ airportData.extraType }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col md:w-2/4 ">
            <span><strong> Origin Name:</strong></span>
          </div>
          <div class="vx-col md:w-2/4 ">
            <span> {{ airportData.originName }}</span>
          </div>
        </div>
      </div>
     <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col md:w-2/4 ">
            <span><strong> Destination Name:</strong></span>
          </div>
          <div class="vx-col md:w-2/4 ">
            <span> {{ airportData.destinationName }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Return Flight No:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ airportData.returnFlightNo }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col md:w-2/4 ">
            <span><strong> Return Flight Depart DateTime:</strong></span>
          </div>
          <div class="vx-col md:w-2/4 ">
            <span> {{ getDateFormat(airportData.returnFlightDepartDateTime)  }}</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col md:w-2/4 ">
            <span><strong> Return Flight Arrival DateTime:</strong></span>
          </div>
          <div class="vx-col md:w-2/4 ">
            <span> {{ getDateFormat(airportData.returnFlightArrivalDateTime)  }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full mt-2">
        <span><strong> Passengers:</strong></span>
      </div>
    </div>
    <!-- <div class="vx-row">
      <template v-for="(pass, passengerIndex) in airportData.passengers">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
      </template>
    </div> -->
    <template v-for="(pass, passengerIndex) in airportData.passengers">
        <div class="vx-row" :key="passengerIndex + '01'">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
        </div>
      </template>
    
  </div>
</template>

<script>
import enquiryViewerStore from "../enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";

export default {
  name: "AirportExtraSearchCriteria",
  components: {
    flatPickr,
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    airportData: {
      type: Object,
    },
    distUnit:{
      type: String
    }
  },
  methods: {
   
    getDateFormat(checkin) {
      var checkinDate = new Date(checkin);
     // var checkoutDate = new Date(checkout);
      //required format 10-Sep-2021

      //for start date time
      var dayin = "";
      if (checkinDate.getDate() < 10) {
        dayin = "0" + checkinDate.getDate();
      } else {
        dayin = checkinDate.getDate() + "";
      }
      var monthin = checkinDate.toLocaleString("default", { month: "short" });
      var yearin = checkinDate.getFullYear();
      var timein = checkinDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      var formattedDateIn = dayin + "-" + monthin + "-" + yearin + " " + timein;
      
    //   // for end date time
    //    var dayout = "";
    //   if (checkoutDate.getDate() < 10) {
    //     dayout = "0" + checkoutDate.getDate();
    //   } else {
    //     dayout = checkoutDate.getDate() + "";
    //   }
    //   var monthout = checkoutDate.toLocaleString("default", { month: "short" });
    //   var yearout = checkoutDate.getFullYear();
    //   var timeout = checkoutDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    //   var formattedDateOut = dayout + "-" + monthout + "-" + yearout + " " + timeout;
    //   //Night Difference
    //   var night = checkoutDate.getDate() - checkinDate.getDate();
     // var formattedDate = formattedDateIn + " - " + formattedDateOut ;
      return formattedDateIn;
    },
  },
};
</script>

<style>
</style>
