<template>
  <div>
    <vs-divider class="mb-5" />
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <span class="leading-none font-medium underline">
          <strong>Car Rental Search Criteria</strong></span
        >
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Pickup Location:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ carRentalData.pickupLocationName }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Dropoff Location:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ carRentalData.dropoffLocationName }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Pickup On Airport Preferred:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ carRentalData.pickupOnAirportPreferred }} </span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Dropoff On Airport Preferred:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ carRentalData.dropoffOnAirportPreferred }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Dates:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ getDateFormat(carRentalData.startDateTime,carRentalData.endDateTime)  }}</span>
          </div>
        </div>
      </div>
    </div>

     <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full"> 
     <GoogleStaticMap  :multiMarkMap="mapdata"></GoogleStaticMap>
      </div>
    </div>
    
  </div>
</template>

<script>
import enquiryViewerStore from "../enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";
import GoogleStaticMap from "./GoogleStaticMap.vue"


export default {
  name: "CarRentalCriteria",
  components: {
    flatPickr,
     GoogleStaticMap
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    carRentalData: {
      type: Object,
    },
    distUnit:{
      type: String
    }
  },
  computed:{    
     mapdata(){
      return this.getMapData();
    }

  },
  methods: {
    getMapData(){
      let dataArray=[];
      let dataObject = {
        radius:0,
        latlon :{},
        placeName:""
      };
      dataObject.latlon = {lat: this.carRentalData.pickupLatitude, lng: this.carRentalData.pickupLongitude};
      dataObject.placeName = this.carRentalData.pickupLocationName;
      dataArray.push(dataObject);
      dataObject.latlon = {lat: this.carRentalData.dropoffLatitude, lng: this.carRentalData.dropoffLongitude};
      dataObject.placeName = this.carRentalData.dropoffLocationName;
      dataArray.push(dataObject);
      return dataArray;

    },
   
    getDateFormat(checkin,checkout) {
      var checkinDate = new Date(checkin);
      var checkoutDate = new Date(checkout);
      //required format 10-Sep-2021

      //for start date time
      var dayin = "";
      if (checkinDate.getDate() < 10) {
        dayin = "0" + checkinDate.getDate();
      } else {
        dayin = checkinDate.getDate() + "";
      }
      var monthin = checkinDate.toLocaleString("default", { month: "short" });
      var yearin = checkinDate.getFullYear();
      var timein = checkinDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      var formattedDateIn = dayin + "-" + monthin + "-" + yearin + " " + timein;
      
      // for end date time
       var dayout = "";
      if (checkoutDate.getDate() < 10) {
        dayout = "0" + checkoutDate.getDate();
      } else {
        dayout = checkoutDate.getDate() + "";
      }
      var monthout = checkoutDate.toLocaleString("default", { month: "short" });
      var yearout = checkoutDate.getFullYear();
      var timeout = checkoutDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      var formattedDateOut = dayout + "-" + monthout + "-" + yearout + " " + timeout;
      //Night Difference
      var night = checkoutDate.getDate() - checkinDate.getDate();
      var formattedDate = formattedDateIn + " - " + formattedDateOut ;
      return formattedDate;
    },
  },
};
</script>

<style>
</style>
