<template>
  <travio-center-container grid-width="full" :pageTitle="pageTitle">
    <vx-card>
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-2">
          <span
            ><strong> {{ searchIdAndKey }}</strong></span
          >
        </div>
      </div>
      <div class="vx-row">
        <!--#region Header field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <span><strong> Search Type:</strong></span>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span> {{ searchCriteria.searchType }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <span><strong> Trip Type:</strong></span>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span> {{ searchCriteria.tripType }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <span><strong> Currency:</strong></span>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span> {{ searchCriteria.currency }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <span><strong> Language:</strong></span>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span> {{ searchCriteria.language }}</span>
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <span><strong> Environment:</strong></span>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span> {{ searchCriteria.environment }}</span>
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <span><strong> Nationality:</strong></span>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span> {{ searchCriteria.nationality }}</span>
            </div>
          </div>
        </div>

        <!--#endregion Currency field ends-->
      </div>
      <FlightSearchCriteria
        v-if="searchCriteria.flightSearchCriteria"
        :flightSearchData="searchCriteria.flightSearchCriteria"
        :distUnit="distanceUnit"
      ></FlightSearchCriteria>
      <AccommodationSearchCriteria
        v-if="searchCriteria.accommodationSearchCriteria"
        :accomSearchData="searchCriteria.accommodationSearchCriteria"
        :distUnit="distanceUnit"
      ></AccommodationSearchCriteria>
      <TicketsAttractionsCriteria
        v-if="searchCriteria.ticketSearchCriteria"
        :ticketSearchData="searchCriteria.ticketSearchCriteria"
        :distUnit="distanceUnit"
      ></TicketsAttractionsCriteria>
      <CarRentalCriteria
        v-if="searchCriteria.carRentalSearchCriteria"
        :carRentalData="searchCriteria.carRentalSearchCriteria"
        :distUnit="distanceUnit"
      ></CarRentalCriteria>
      <TransferSearchCriteria
        v-if="searchCriteria.transferSearchCriteria"
        :transferData="searchCriteria.transferSearchCriteria"
        :distUnit="distanceUnit"
      ></TransferSearchCriteria>
      <AirportExtraSearchCriteria
        v-if="searchCriteria.airportExtrasSearchCriteria"
        :airportData="searchCriteria.airportExtrasSearchCriteria"
        :distUnit="distanceUnit"
      ></AirportExtraSearchCriteria>

      <div v-if="stageDropDown.length > 0" class="vx-row mt-6">
       <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span></span>
            </div>
            <div class="vx-col w-full">
              <v-select
                v-model="bookStageId"
                :reduce="(x) => x.code"
                :clearable="false"
                :options="stageDropDown"
              />
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-button class=""  @click="VisitSite"
          >Visit Site</vs-button>
        </div>
      </div>
      

      <div class="flex flex-wrap justify-end">
        <vs-button v-if="readOnly" @click="handleCancel" class="mt-4"
          >Back</vs-button
        >
      </div>
    </vx-card>
  </travio-center-container>
</template>

<script>
import { Form } from "@/core-modules/form-framework/Form.js";
import helper from "./enquiryViewerHelper";
import enquiryViewerStore from "./enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";
import FlightSearchCriteria from "./product-search-criteria/FlightSearchCriteria.vue";
import AccommodationSearchCriteria from "./product-search-criteria/AccommodationSearchCriteria.vue";
import TicketsAttractionsCriteria from "./product-search-criteria/TicketsAttractionsCriteria.vue";
import CarRentalCriteria from "./product-search-criteria/CarRentalCriteria.vue";
import TransferSearchCriteria from "./product-search-criteria/TransferSearchCriteria.vue";
import AirportExtraSearchCriteria from "./product-search-criteria/AirportExtraSearchCriteria.vue";

export default {
  components: {
    flatPickr,
    FlightSearchCriteria,
    AccommodationSearchCriteria,
    TicketsAttractionsCriteria,
    CarRentalCriteria,
    TransferSearchCriteria,
    AirportExtraSearchCriteria,
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
  },
  data() {
    return {
      helper: helper,
      searchCriteria: {},
      searchId: null,
      searchKey: null,
      distanceUnit: "",
      stageDropDown: [],
      bookStageId: null,
      stageDropDownSource: [
        { label: "Search", code: 1 },
        { label: "Availability", code: 3 },
        { label: "Basket", code: 4 },
      ],
    };
  },

  computed: {
    enquiryViewerFormData() {
      return this.$store.state.enquiryViewerStore.formData;
    },
    searchIdAndKey() {
      if (this.searchId && this.searchKey) {
        return `${this.searchId}/${this.searchKey}`;
      } else {
        return "";
      }
    },
    pageTitle() {
      return "Search Criteria Details: " + this.$route.params.webReference;
    },
  },
  methods: {
    handleCancel() {
      this.$router.push({
        name: "enquiry-viewer-list",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    VisitSite(){      
      if(this.bookStageId === 1){
        const data = _.filter(this.enquiryViewerFormData,(x) => x.settingName === 'WebsiteResultsPageUrl');
        const url = data[0].settingValue + '#/results/searchSession=' + this.searchIdAndKey;
        window.open(url, '_blank');
      }
       if(this.bookStageId === 3){
        const data = _.filter(this.enquiryViewerFormData,(x) => x.settingName === 'WebsiteAvailPageUrl');
        const url = data[0].settingValue + '#/searchSession=' + this.searchIdAndKey;
        window.open(url, '_blank');
      }
       if(this.bookStageId === 4){
        const data = _.filter(this.enquiryViewerFormData,(x) => x.settingName === 'WebsiteBasketPageUrl');
        const url = data[0].settingValue + '#/searchSession=' + this.searchIdAndKey;
        window.open(url, '_blank');
      }
    }
  },

  async created() {
    if (!enquiryViewerStore.isRegistered) {
      this.$store.registerModule("enquiryViewerStore", enquiryViewerStore);
      enquiryViewerStore.isRegistered = true;
    }
    //#region fetch required data from api and store in state
    this.$vs.loading();
    this.$store
      .dispatch(
        "enquiryViewerStore/fetchEnquiryViewer",
        this.$route.params.applicationId
      )
      .then(() => {
        return this.$store.dispatch(
          "enquiryViewerStore/fetchFormData",
          this.$route.params.applicationId
        );
      })
      .catch((err) => console.error(err))
      .finally(() => this.$vs.loading.close());
    //#endregion

    try {
      this.$vs.loading();
      const response = await axios.get(
        `api/travelifyenquiry/${this.$route.params.applicationId}/searches/${this.$route.params.webReference}`
      );
      const dataToBeDisplayed = response.data;
      //check if data for given webReference exist
      if (!dataToBeDisplayed) {
        this.$_notifyFailure("No data available for given web reference");
        this.$router.push(
          `/applications/${this.$route.params.applicationId}/enquiryViewer`
        );
      }
      this.searchCriteria = Object.assign({}, dataToBeDisplayed);
      this.searchKey = dataToBeDisplayed.searchKey;
      this.searchId = dataToBeDisplayed.searchId;
      this.distanceUnit = dataToBeDisplayed.distanceUnit;
      if (dataToBeDisplayed.bookStageId) {  
        this.bookStageId = dataToBeDisplayed.bookStageId[0];     
        this.stageDropDown = this.stageDropDownSource.filter(o1 => dataToBeDisplayed.bookStageId.some(o2 => o1.code === o2));
      }
      //this.searchCriteria = dataToBeDisplayed;
      this.$vs.loading.close();
    } catch (err) {
      console.log(err);
      this.$vs.loading.close();
    }

    //workable version
    // this.$vs.loading();
    //     this.$http
    //       .get(
    //         `api/travelifyenquiry/${this.$route.params.applicationId}/searches/${this.$route.params.webReference}`
    //       )
    //       .then((response) => {
    //         const dataToBeDisplayed = response.data;
    //         //check if data for given webReference exist
    //         if (!dataToBeDisplayed) {
    //           this.$_notifyFailure("No data available for given web reference");
    //           this.$router.push(
    //             `/applications/${this.$route.params.applicationId}/enquiryViewer`
    //           );
    //         }
    //         Object.assign(this.searchCriteria, dataToBeDisplayed);
    //       })
    //       .catch((error) => {
    //         this.$_notifyFailureByResponseData(error.response.data);
    //         this.$router.push(
    //           `/applications/${this.$route.params.applicationId}/enquiryViewer`
    //         );
    //       })
    //       .finally(() => this.$vs.loading.close());
  },
};
</script>

<style>
</style>
