import axios from "@/axios.js"
import _ from 'lodash'


export default {
  isRegistered: false,
  namespaced: true,
  state: {
    enquiryViewer: [],
    formData:{},
    deleteDialog: { show: true, enquiryViewerId: 0, affectedUsers: 0 }
  },
  mutations: {
    SET_ENQUIRY_VIEWER_LIST(state, enquiryViewer) {     
      state.enquiryViewer = enquiryViewer;
    },
    SET_FORM_DATA(state, formData) {
      state.formData = formData;
    },
    ADD_ENQUIRY_VIEWER(state, enquiryViewer) {
      state.enquiryViewer.push(enquiryViewer)
    },
    UPDATE_ENQUIRY_VIEWER(state, enquiryViewer) {
      const priceRuleIndex = _.findIndex(state.enquiryViewer, (item) => item.id === enquiryViewer.enquiryViewerId)
      state.enquiryViewer[priceRuleIndex] = enquiryViewer
    },
    REMOVE_ENQUIRY_VIEWER(state, enquiryViewerId) {
      const priceRuleIndex = _.findIndex(state.enquiryViewer, (item) => item.id === enquiryViewerId)
      state.enquiryViewer.splice(priceRuleIndex, 1)
    },
    SET_DELETE_DIALOG(state, deleteDialog) {
      state.deleteDialog = deleteDialog
    }
  },
  actions: {
    fetchFormData({ commit },payload) {      
      return new Promise((resolve, reject) => {
        const url =  `api/travelifyenquiry/formdata/${payload}`;
        axios.get(url)
          .then(response => {
            commit('SET_FORM_DATA', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchEnquiryViewer({ commit }, payload) {     
      return new Promise((resolve, reject) => {
        const url = `api/travelifyenquiry/${payload}/searches`;
        axios.get(url)
          .then(response => {
            commit('SET_ENQUIRY_VIEWER_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
      
    },
    addEnquiryViewer({ commit }, payload) {
     
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.priceRuleAPIs.addAPI, payload.applicationId);
        axios.post(url, { ruleData: JSON.stringify(payload.data) })
          .then(response => {            
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    updateEnquiryViewer({ commit }, payload) {
      return new Promise((resolve, reject) => {
      const url = helper.StringFormat(helper.priceRuleAPIs.updateAPI, payload.applicationId, payload.priceRuleId);
      axios.put(url, { ruleData: JSON.stringify(payload.data) })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    })

    },
    removeEnquiryViewer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.priceRuleAPIs.deleteAPI, payload.applicationId, payload.priceRuleId);
        axios.delete(url)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
  }
}

