//#region DateTime Formatter
const dateFormatter = {   
    date :(date,dateFormat) => {
        let createdDate = "";
        let currentMonth = new Date(date).getMonth() + 1;
        let month = currentMonth > 9 ? `${currentMonth}` : `0${currentMonth}`;
        let currentDay = new Date(date).getDate();
        let day = currentDay > 9 ? `${currentDay}` : `0${currentDay}`;
        if(dateFormat === 'dd/mm/yyyy' || dateFormat === 'd/M/yyyy'){
            createdDate = `${day}/${month}/${new Date(date).getFullYear()}`;
        }
        if(dateFormat === 'mm/dd/yyyy' || dateFormat === 'M/d/yyyy'){
            createdDate = `${month}/${day}/${new Date(date).getFullYear()}`;
        }
        return createdDate;
    },
    flatDate :(dateFormat) => {
        let format = '';
        if(dateFormat === 'dd/mm/yyyy'){
            format = 'd/m/Y';
        }
        if(dateFormat === 'mm/dd/yyyy'){
            format = 'm/d/Y';
        }
        return format;
    },
  
}
//#endregion

const enquiryViewerHelper = {
    dateFormatter : dateFormatter
}

export default enquiryViewerHelper;
