<template>
  <div>
    <vs-divider class="mb-5" />
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <span class="leading-none font-medium underline">
          <strong>Ticket Search Criteria</strong></span
        >
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Location:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ ticketSearchData.locationName }} ({{ ticketSearchData.radius }} {{distUnit}})</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Dates:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ getDateFormat(ticketSearchData.startDate,ticketSearchData.endDate)  }}</span>
          </div>
        </div>
      </div>
     
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Ticket Type:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ ticketSearchData.ticketType }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="vx-col w-full md:w-1/3">
        <div class="vx-row">
          <div class="vx-col md:w-3/4 w-full">
            <span><strong> Latitude:</strong></span>
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <span> {{ ticketSearchData.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/3">
        <div class="vx-row">
          <div class="vx-col md:w-3/4 w-full">
            <span><strong> Longitude:</strong></span>
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <span> {{ ticketSearchData.longitude  }}</span>
          </div>
        </div>
      </div> -->
     
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full mt-2">
        <span><strong> Participants:</strong></span>
      </div>
    </div>
    <!-- <div class="vx-row">
      <template v-for="(pass, passengerIndex) in ticketSearchData.participants">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
      </template>
    </div> -->
    <template v-for="(pass, passengerIndex) in ticketSearchData.participants">
        <div class="vx-row" :key="passengerIndex + '01'">
        <div
          class="vx-col"
          :class="pass.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="passengerIndex + '0'"
        >
          <span> {{ pass.type }}</span>
        </div>
        <div
          v-if="pass.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="passengerIndex + '1'"
        >
          <span>Age: {{ pass.age }}</span>
        </div>
        </div>
      </template>

      <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full"> 
     <GoogleStaticMap :multiMarkMap="mapdata"></GoogleStaticMap>
      </div>
    </div>

    
  </div>
</template>

<script>
import enquiryViewerStore from "../enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";
import GoogleStaticMap from "./GoogleStaticMap.vue"

export default {
  name: "TicketsAttractionsCriteria",
  components: {
    flatPickr,
    GoogleStaticMap
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    ticketSearchData: {
      type: Object,
    },
    distUnit:{
      type: String
    }
  },
  computed:{
     mapdata(){
      return this.getMapData();
    }
  },
  methods: {
     getMapData(){
      let dataArray=[];
      let dataObject = {
        radius:0,
        latlon :{},
        placeName:""
      };
      dataObject.radius = this.distUnit.toLowerCase() === 'miles' ? Math.round(this.ticketSearchData.radius * 1609.34) : Math.round(this.ticketSearchData.radius * 1000);
      dataObject.latlon = {lat: this.ticketSearchData.latitude, lng: this.ticketSearchData.longitude};
      dataObject.placeName = this.ticketSearchData.locationName;
      dataArray.push(dataObject);
      return dataArray;

    },
   
    getDateFormat(checkin,checkout) {
      var checkinDate = new Date(checkin);
      var checkoutDate = new Date(checkout);
      //required format 10-Sep-2021
      //for check in
      var dayin = "";
      if (checkinDate.getDate() < 10) {
        dayin = "0" + checkinDate.getDate();
      } else {
        dayin = checkinDate.getDate() + "";
      }
      var monthin = checkinDate.toLocaleString("default", { month: "short" });
      var yearin = checkinDate.getFullYear();
      var formattedDateIn = dayin + "-" + monthin + "-" + yearin;
      // for checkout
       var dayout = "";
      if (checkoutDate.getDate() < 10) {
        dayout = "0" + checkoutDate.getDate();
      } else {
        dayout = checkoutDate.getDate() + "";
      }
      var monthout = checkoutDate.toLocaleString("default", { month: "short" });
      var yearout = checkoutDate.getFullYear();
      var formattedDateOut = dayout + "-" + monthout + "-" + yearout;
      //Night Difference
      var night = checkoutDate.getDate() - checkinDate.getDate();
      var formattedDate = formattedDateIn + " - " + formattedDateOut ;
      return formattedDate;
    },
  },
};
</script>

<style>
</style>
